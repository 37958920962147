import { Pipe, PipeTransform } from '@angular/core';

import { Mapper } from '@sulser-print/models/shared/mapper';

@Pipe({ name: 'mapper', standalone: true })
export class MapperPipe implements PipeTransform {
	/**
	 * Maps object to an arbitrary result through a mapper function
	 *
	 * @param value an item to transform
	 * @param mapper a mapping function
	 * @param args arbitrary number of additional arguments
	 */
	transform<T extends unknown[], U, G>(value: U, mapper: Mapper<[U, ...T], G>, ...arguments_: T): G {
		return mapper(value, ...arguments_);
	}
}
